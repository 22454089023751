import { render, staticRenderFns } from "./create-dashboard-tab-modal.vue?vue&type=template&id=7ccf129f&scoped=true&"
import script from "./create-dashboard-tab-modal.vue?vue&type=script&lang=js&"
export * from "./create-dashboard-tab-modal.vue?vue&type=script&lang=js&"
import style0 from "./create-dashboard-tab-modal.vue?vue&type=style&index=0&id=7ccf129f&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7ccf129f",
  null
  
)

export default component.exports