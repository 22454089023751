<template>
  <div class="nav-tabs-custom">
    <ul class="nav nav-tabs">
      <li class="active">
        <a href="#tab_1" data-toggle="tab"
          ><i class="fa fa-info-circle"></i> {{ $t("general") }}</a
        >
      </li>
      <li>
        <a href="#tab_2" data-toggle="tab">
          <i class="fa fa-tags"></i>
          {{ $t("data_list") }}</a
        >
      </li>
    </ul>
    <div class="tab-content">
      <div class="tab-pane active" id="tab_1">
        <div>
          <div class="row">
            <div class="col-sm-6 col-xs-12">
              <div class="form-group">
                <label for="contract_name">
                  {{ $t("contract_name") }}
                  <Tooltip :title="$t('contract_name_hint')" />
                </label>
                <input
                  type="text"
                  class="form-control"
                  name="contract_name"
                  id="contract_name"
                  v-model="contractName"
                />
              </div>
            </div>
          </div>

          <PortalData
            v-if="portalData"
            v-model="portalData"
            :default="defaultPortalData"
            v-slot="{ portal_data }"
          >
            <div class="row">
              <div class="col-sm-6 col-xs-12">
                <DashboardTab
                  v-model="portal_data.defaultDashboardTab"
                  :label="$t('default_dashboard_for_contract')"
                  :hint="$t('titles.default_dashboard_for_contract')"
                  :tabs="dashboardTabs"
                  context="contract"
                  @tab:remove="removeTab"
                />
                <div v-if="removingTabs.length">
                  <strong class="text-danger"
                    >{{ $t("removing_tabs") }}
                    <span
                      class="btn btn-danger btn-xs"
                      v-for="(tab, index) in removingTabs"
                      :key="tab.name"
                      :title="$t('cancel')"
                      @click="removeTab(index)"
                      style="margin: 1px"
                      data-testid="removing-tab"
                      >{{ tab.title }} <span class="fa fa-remove"></span></span
                  ></strong>
                  <em
                    class="text-warning"
                    style="
                      display: block;
                      margin-top: 0.5em;
                      margin-bottom: 1em;
                    "
                    >{{
                      $t("changes_will_be_performed_after_saving", {
                        save: $t("save")
                      })
                    }}</em
                  >
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-6 col-xs-12">
                <PoolingIntervalSelector
                  v-model="manualRefreshTimeout"
                  :list="manualRefreshIntervalList"
                  label="manual_pooling_interval"
                  tooltip="manual_pooling_interval_ui"
                />
              </div>
            </div>
            <BaseButton
              type="primary"
              @click="save"
              :disabled="loading"
              data-testid="save"
            >
              {{ loading ? $t("saving") + "..." : $t("save") }}
            </BaseButton>
          </PortalData>
        </div>
      </div>
      <div class="tab-pane" id="tab_2">
        <FormTextListInline />
      </div>
    </div>
  </div>
</template>

<script>
import ContractService from "@/services/contract.js";
import PortalData from "@/components/registration/portal-data";
import Tooltip from "@/components/tooltip";
import DashboardTab from "@/components/dashboard-tab";
import BaseButton from "@/components/base/buttons/base-button";
import PoolingIntervalSelector from "@/components/pooling-interval-selector.vue";
import MixinRuleValidation from "@/project/mixin-rule-validation.js";
import MixinAlert from "@/project/mixin-alert.js";
import FormTextListInline from "@/components/registration/form-text-list-inline.vue";
export default {
  name: "ContractSettings",
  mixins: [MixinRuleValidation, MixinAlert],
  components: {
    PortalData,
    DashboardTab,
    BaseButton,
    PoolingIntervalSelector,
    Tooltip,
    FormTextListInline
  },
  data() {
    return {
      contractName: "",
      portalData: {},
      removingTabs: [],
      loading: false
    };
  },
  computed: {
    contract() {
      return this.$store.getters["user/contract"] || {};
    },
    defaultPortalData() {
      return {
        defaultDashboardTab: this.$root.config.equipment_selection.tabs.initial
      };
    },
    dashboardTabs() {
      return (this.$root.config.equipment_selection.tabs.items || [])
        .filter((t) => t.enabled && (!t.rule || this.hasPermission(t.rule)))
        .concat(this.portalData?.userTabs || []);
    },
    selectedTab() {
      return this.dashboardTabs.find(
        (t) => t.name == this.portalData.defaultDashboardTab
      );
    },
    manualRefreshTimeout: {
      set(value) {
        this.$set(this.portalData, "manualRefreshTimeout", parseInt(value));
      },
      get() {
        return this?.portalData?.manualRefreshTimeout || 10000;
      }
    },
    manualRefreshIntervalList() {
      return [
        { value: 10000, label: "10s" },
        { value: 30000, label: "30s" },
        { value: 60000, label: "1min" },
        { value: 150000, label: "2.5 min" },
        { value: 300000, label: "5 min" },
        { value: 600000, label: "10 min" },
        { value: 900000, label: "15 min" }
      ];
    }
  },
  watch: {
    "contract.portal_data": {
      immediate: true,
      deep: true,
      handler(val) {
        this.portalData = val ? JSON.parse(JSON.stringify(val)) : {};
      }
    }
  },
  methods: {
    removeTab(index) {
      if (typeof index == "number") {
        // cancel removing of tab in given index
        this.portalData.userTabs.push(this.removingTabs.splice(index, 1)[0]);
      } else {
        // add selected tab to removing list
        this.removingTabs.push(this.selectedTab);
        // reset default tab to portal default
        this.$nextTick(() => {
          this.portalData.defaultDashboardTab = this.$root.config.equipment_selection.tabs.initial;
        });
        // removes tab from portal data
        this.portalData.userTabs.splice(
          this.portalData.userTabs.findIndex(
            (t) => t.name == this.selectedTab.name
          ),
          1
        );
      }
    },
    save() {
      if (this.loading) return;
      let payload = {
        id: this.contract.id,
        etag: this.contract.etag,
        portal_data: this.portalData,
        name: this.contractName
      };
      this.loading = true;
      this.service.updateContract(payload).then((response) => {
        this.loading = false;
        if (response && typeof response == "object" && response.id) {
          this.$store.dispatch("user/configureUserContract"); // config contract
          this.$emit("save", response);
          this.removingTabs = [];
        } else {
          this.$swal({
            title: this.$t("item_could_not_be_saved"),
            text: this.$t("invalid_data"),
            icon: "error"
          });
        }
      });
    }
  },
  created() {
    this.service = new ContractService();
    this.contractName = this.contract.name;
  }
};
</script>

<style></style>
