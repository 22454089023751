<template>
  <div>
    <div class="row">
      <div class="col-xs-12 col-md-6">
        <InfoCard 
          boxClass="bg-blue" 
          icon="fa fa-credit-card"
          progress="100"
          :forceShowProgress="true"
          :text="$t('contracted_plan')"
          :textStrong="planName"
          numberIcon="fa fa-bar-chart"
          :number="$t('data_range')"
          :numberStrong="maximumData"
          progressDescriptionIcon="fa fa-refresh"
          :progressDescription="$t('periodicity')"
          :progressDescriptionStrong="commitment"
        />
      </div>
      <div class="col-xs-12 col-md-6">
        <InfoCard 
          :boxClass="contractStatusBoxClass" 
          :icon="contractStatusIconClass"
          progress="100"
          :forceShowProgress="true"
          :text="$t('plan_status')"
          :textTooltip="$t('contract_status')"
          :textStrong="contractState"
          numberIcon="fa fa-usd"
          :number="$t('plan_value')"
          :numberStrong="formattedPlanValue"
          progressDescriptionIcon="fa fa-calendar"
          :progressDescription="$t('expires_at')"
          :progressDescriptionStrong="formattedExpiresAt"
        />
      </div>
    </div>
    <div
      class="row summary"
      style="margin-bottom: 15px;  display:flex;   flex-wrap: wrap;"
    >
      <template v-if="isRoot">
        <div class="col-xs-12 col-md-4">
          <ResourceUsageChart
            target="device"
            style="overflow:hidden;"
          />
        </div>
        <div class="col-xs-12 col-md-4">
          <ResourceUsageChart
            target="data"
            style="overflow:hidden;"
            :hideTotalWhenExceeds="maxCustomPlanData"
            :hideChartWhenExceeds="maxCustomPlanData"
          />
        </div>
        <div class="col-sm-12 col-md-4" style="flex-grow:1">
          <CurrentPlanUsage 
            style="overflow:hidden;" 
            @upgrade-plan="upgradePlan"
            :maxCustomPlanData="maxCustomPlanData"
          />
        </div>
      </template>
      <template v-else>
        <div class="col-xs-12 col-md-6">
          <ResourceUsageChart
            target="device"
            style="overflow:hidden;"
          />
        </div>
        <div class="col-xs-12 col-md-6">
          <ResourceUsageChart
            target="data"
            style="overflow:hidden;"
            :hideTotalWhenExceeds="maxCustomPlanData"
            :hideChartWhenExceeds="maxCustomPlanData"
          />
        </div>
      </template>
    </div>
    <div class="row">
      <div class="col-xs-12">
        <ResourcesUsageTable />
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import ResourceUsageChart from "@/components/statistics/resource-usage-chart";
import CurrentPlanUsage from "@/components/statistics/current-plan-usage";
import ResourcesUsageTable from "@/components/statistics/resources-usage-table";
import InfoCard from "@/components/widgets/info-card";
import Utils from "@/plugins/utils.js";

import { trialDaysConst, trialDaysLeftNotificationConst, contractStateConst } from "@/assets/constants.js";
import { mapActions } from "vuex";

Vue.use(Utils);

export default {
  name: "UsageStatistics",
  props: [
    "planName", "maximumData", "commitment", "planValue", 
    "contractState", "expiresAt"
  ],
  components: {
    ResourceUsageChart,
    CurrentPlanUsage,
    ResourcesUsageTable,
    InfoCard
  },
  data() {
    return {
      loading: false,
      maxCustomPlanData: 999999
    };
  },
  computed: {
    isRoot() {
      return this.$store.getters["user/loggedUser"].is_root;
    },
    contract() {
      return this.$store.getters["user/contract"] || null;
    },
    contractStateId() {
      return parseInt(this?.contract?.contract_state?.id || 0);
    },
    contractTrialDays() {
      if (!this.isTest) return false;
      let days = moment(new Date()).diff(this.contract.trial_started_at, "days");
      return days;
    },
    isTest() {
      return this?.contract?.contract_state?.id == contractStateConst.TEST;
    },
    contractStatusBoxClass() {
      if (!this?.contract) return "bg-gray";

      switch (this.contractStateId) {
        case contractStateConst.TEST:
          if (this.contractTrialDays > trialDaysConst) {
            return "bg-red";
          } else if (this.contractTrialDays >= (trialDaysConst - trialDaysLeftNotificationConst)) {
            return "bg-yellow";
          } else {
            return "bg-green";
          }
        case contractStateConst.CONTRACTED: return "bg-green";
        case contractStateConst.RENEWAL: return "bg-yellow";
        case contractStateConst.PENDING: return "bg-yellow";
        case contractStateConst.NOTCONFIRMED: return "bg-red";
        case contractStateConst.DEVELOPMENT: return "bg-aqua";
        default: return "bg-red";
      }
    },
    contractStatusIconClass() {
      if (this.loading || !this?.contract) return "fa fa-check";
      switch (parseInt(this?.contract?.contract_state?.id)) {
        case contractStateConst.TEST:
          if (this.contractTrialDays > trialDaysConst) {
            return "fa fa-ban";
          } else if (this.contractTrialDays >= (trialDaysConst - trialDaysLeftNotificationConst)) {
            return "fa fa-warning";
          } else {
            return "fa fa-check";
          }
        case contractStateConst.CONTRACTED: return "fa fa-check";
        case contractStateConst.RENEWAL: return "fa fa-warning";
        case contractStateConst.PENDING: return "fa fa-warning";
        case contractStateConst.NOTCONFIRMED: return "fa fa-warning";
        case contractStateConst.DEVELOPMENT: return "fa fa-flask";
        default: return "fa fa-ban";
      }
    },
    formattedPlanValue() {
      if (this.planValue == null) return '';
      if (parseFloat(this.planValue) <= 0) {
        return this.$t('free');
      }
      return 'R$ '+this.$utils.numberToReal(parseFloat(this.planValue));
    },
    formattedExpiresAt() {
      if (this.expiresAt == null) return '';
      let minusOneSecond = new Date(this.expiresAt);
      minusOneSecond.setSeconds(minusOneSecond.getSeconds() - 60);
      return moment(minusOneSecond).format("L");
    }
  },
  methods: {
    upgradePlan() {
      this.$emit("upgrade-plan");
    },
    showError(e) {
      this.$toasted.show(this.$tc(e), {
        icon: "fa fa-warning",
        type: "error",
        keepOnHover: true,
        action: {
          text: this.$tc("retry"),
          onClick: () => this.loadUsage()
        }
      });
    },
    async loadUsage() {
      this.loading = true;
      try {
        await this.fetchContractUsage();
      } catch (e) {
        this.showError(e);
      } finally {
        this.loading = false;
      }
    },
    ...mapActions("user", ["fetchContractUsage"])
  },
  mounted() {
    this.loadUsage();
  }
};
</script>

<style scoped>
.title-icon {
  font-size: 1.1em;
  vertical-align: text-bottom;
  margin-right: 0.2em;
}

::v-deep .statistics-box-container {
  height: 100%;
}

div.summary > div {
  padding-bottom: 10px;
}
</style>
